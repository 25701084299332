import { Component } from '@angular/core';
import { scrollToTop } from '@shared/utils/helper';
import { environment } from '@env/environment';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import { HeaderService } from '@modules/hotel-booking/services/header/header.service';
import { CtaSigninService } from '@modules/hotel-booking/services/cta-signin/cta-signin.service';
import { PropertyManagementControllerService } from '@booking/angular-booking-user-data-service';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';
import { ActivatedRoute } from '@angular/router';
import { ChannelManagerControllerService } from '@booking/angular-booking-generic-service';
import { catchError, firstValueFrom, of, timeout } from 'rxjs';
import { TIME_OUT_REQUEST } from '@shared/constant';
import { SocchatService } from '@modules/hotel-booking/services/socchat-sevice/socchat.service';

@Component({
  selector: 'app-view-hotel-approval-review',
  templateUrl: './view-hotel-approval-review.component.html',
  styleUrls: ['./view-hotel-approval-review.component.scss'],
})
export class ViewHotelApprovalReviewComponent {
  propertyId: string;
  generalInfoData: GeneralInfoDTO;
  contractInfoData: any;
  paymentInfoData: any;
  managerSystem: any;
  isCM: boolean = false;

  constructor(
    private headerService: HeaderService,
    private footerService: FooterService,
    private ctaSigninService: CtaSigninService,
    private propertyManagementControllerService: PropertyManagementControllerService,
    private activatedRoute: ActivatedRoute,
    private channelManagerControllerService: ChannelManagerControllerService,
    private socchatService: SocchatService
  ) {
    const softSessionId = localStorage.getItem(environment.SOFT_SESSION_ID);
    if (softSessionId) {
      localStorage.removeItem(environment.SOFT_SESSION_ID);
    }
    this.getPropertyId();
  }
  async ngOnInit() {
    scrollToTop();
    this.headerService.setPropertyHeader({
      isShow: false,
      isFixed: false,
      isChangeColor: false,
      isWhite: false,
    });
    this.footerService.setIsShowFooter(false);
    this.ctaSigninService.hideCTA();
    this.getAllChannelManager();
    this.getPropertyInfoReviewByPropertyId();
    this.socchatService.setFloatChatBtn(false);
  }

  getPropertyId() {
    this.propertyId = this.activatedRoute.snapshot.paramMap.get('property_id')!;
  }

  getAllChannelManager() {
    this.channelManagerControllerService
      .getAllChannelManager()
      .pipe(
        timeout(TIME_OUT_REQUEST),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            this.showError('TIME_OUT_REQUEST');
          } else if (error.status === 0) {
            this.showError('ERR_INTERNET_DISCONNECTED');
          } else {
            this.showError(error.error.error);
          }
          return of(error);
        }),
      ).subscribe({
        next: (res) => {
          if (res.data) {
            this.managerSystem = res.data;
          }
        }
      })
  }

  showError(arg0: string) {
    throw new Error(arg0);
  }
  
  getPropertyInfoReviewByPropertyId() {
    this.propertyManagementControllerService.getPropertyInfoReviewByPropertyId(this.propertyId).subscribe({
      next: (res) => {
        if(res.success) {
          this.generalInfoData = res.data.general_info;
          this.contractInfoData = res.data.contract_info;
          this.paymentInfoData = res.data.payment_info;
        }
      }
    })
  }
}
