import { Component, EventEmitter, inject, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientSBannerControllerService, ResponseModelListSBannerDTO, SystemBannerControllerService } from '@soctrip/angular-banner-service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-slide-banner',
  templateUrl: './slide-banner.component.html',
  styleUrls: ['./slide-banner.component.scss']
})
export class SlideBannerComponent {
  @Input() code: string;
  @Input() iframeClass: string;
  @Input() imgClass: string;
  @Input() country: string;
  @Input() imageDefault = '';
  @Output() hiddenBanner: EventEmitter<any> = new EventEmitter();
  @Output() getDataBanner:  EventEmitter<any> = new EventEmitter();
  listBanners: any[] = [];
  dataBanner: ResponseModelListSBannerDTO;
  baseUrl = environment.baseURL;
  responsiveOptions: any;
  slideshows: any[] = [];
  loading: boolean = true;
  bannerCountry: string;
  private clientBannerService = inject(ClientSBannerControllerService);
  private systemBannerService = inject(SystemBannerControllerService);
  private route = inject(ActivatedRoute);
  
  ngOnInit(): void {
    this.getBannerInfo()
   }  
 
   ngOnChanges(changes: SimpleChanges) {
     if (changes['country'] && changes['country'].currentValue) {
       this.getBannerInfo()
     }
   }
   
  getBannerInfo(){
    this.route.queryParamMap.subscribe((queryParams) => {
      const bannerId = queryParams.get('preview_banner');
      const targetCode = queryParams.get('banner_code');  
      if(!this.country || this.country == this.bannerCountry) return
      this.bannerCountry = this.country;
      if (bannerId && targetCode?.toUpperCase() === this.code.toUpperCase()) {
        this.getPreviewBannerById(bannerId); // get Preview banner by Id
      } else {
        this.getOnGoingBanner(); //get banner on going
      }      
    });
  }

  getOnGoingBanner() {
    this.clientBannerService.getBannerByCode(this.code, this.country).subscribe({
      next: (res) => {
        if (res.success) {
          const banners = res.data ? res.data : [];
          banners.forEach(banner => {
            let media = banner.medias ? banner.medias[0] : undefined;
            if(media && media.id) {
              media.url = banner.url;
              this.listBanners.push(media);
            }            
          });
          this.loading = false;
          if(this.listBanners.length == 0)
            this.hiddenBanner?.emit(true);
        } else {
          this.loading = false;
          this.hiddenBanner?.emit(true);
        }
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
        this.hiddenBanner?.emit(true);
      },
    });
  }

  getPreviewBannerById(bannerID: string) {
    this.systemBannerService.getBannerById(bannerID)
    .subscribe({
      next: (res) => {
        if (res.success) {
          const media = res.data?.medias?.[0];
          if(media) {
            media.url = res.data?.url;
            this.listBanners.push(media)
          }else{
            this.hiddenBanner?.emit(true);
          }
          this.loading = false;
        }else{
          this.loading = false;
          this.hiddenBanner?.emit(true);
        }
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
        this.hiddenBanner?.emit(true);
      },
    });
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/home/banner/slideShow1.webp';
  }

  openInNewTab(url: string): void {   
    if (url) {
      window.location.href = url;
    }
  }
}
