<div class="!px-4 !pt-3 !pb-2 font-inter">
    <div class="info flex flex-row justify-between items-center">
        <div class="flex flex-row items-center">
            <span *ngIf="item.created_by?.avatar_thumbnail_url then user; else guest"></span>
            <ng-template #user>
                <img class="!h-10 !w-10 rounded-full"
                    src="{{baseUrl}}/storage/files/web/{{item.created_by?.avatar_thumbnail_url}}.webp"
                    alt=""
                    onerror="this.src='assets/images/default_user_avatar.png'">
            </ng-template>
            <ng-template #guest>
                <div class="flex items-center flex-col">
                    <i class="sctr-icon-solid-user-02 !w-10 !h-10 !leading-10 text-2xl text-center rounded-full bg-palette-gray-200 text-palette-gray-700"></i>
                </div>
            </ng-template>
            <div class="!ml-3">
                <p class="text-xs sm:text-base font-medium text-palette-gray-700">
                  {{ (item.created_by?.first_name || item.created_by?.last_name) ? item.created_by.full_name : 'DETAIL.GUEST' | translate }}
                </p>
                <p class="text-xs sm:text-sm font-normal text-palette-gray-500">{{ item.created_at | dateFormat: true}}</p>
            </div>
        </div>
        <div class="flex flex-row items-center !gap-2">
                <p *ngIf="item.rating >= 9" class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.SUPERB' | translate }}</p>
                <p *ngIf="item.rating >= 8 && item.rating < 9" class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.EXCELLENT' | translate}}</p>
                <p *ngIf="item.rating >= 7 && item.rating < 8" class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.VERY_GOOD' | translate }}</p>
                <p *ngIf="item.rating >= 6 && item.rating < 7"class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.PLEASANT' | translate}}</p>
                <p *ngIf="item.rating < 6"class="!ml-2 text-base font-medium text-palette-gray-700 first-letter:uppercase">{{ 'DETAIL.UNCOMFORTABLE' | translate }}</p>
            <div class="box-border py-[2px] !px-2 rounded border border-palette-blue-600 w-fit h-[22px] text-xs leading-[18px] font-medium text-palette-blue-600">{{ item.rating }}</div>
        </div>
    </div>
    <div class="flex flex-col 2xl:flex-row 2xl:items-center !my-2 text-palette-gray-700 flex-wrap mb-[2px]">
        <div class="flex flex-row items-center gap-2 text-xs sm:text-sm font-medium border-r-palette-gray-200 border-r pr-2">
            <p class="">{{ 'DETAIL.ROOM' | translate }}: {{ item.factors[0].type }}</p>
            <div class="relative" *ngIf="item.factors.length > 1">
                <p [tooltip]="listTypeRoomToolTip" [theme]="TooltipTheme.LIGHT"
                    [position]="TooltipPosition.ABOVE"
                    class="cursor-pointer line-clamp-1 !text-palette-gray-700 bg-palette-gray-200 !font-inter !text-xs !leading-[18px] !font-medium flex justify-center items-center rounded-2xl">
                    <span class="!px-2 !py-[2px]">+{{item.factors.length - 1}}</span>
                </p>
            </div>
        </div>
        <div #hotelPoints class="flex flex-row flex-wrap sm:items-center text-xs font-medium !leading-[18px] ml-2">
            <div *ngFor="let note of item.notes">
              <ng-template [ngIf]="note.content != OVERALL_RATING_TEXT_CONTENT">
                  <p class="!mt-2 !mr-1 2xl:!mt-0 !py-[2px] !px-2 rounded-2xl bg-palette-gray-200">
                      {{ getTranslateExperience(note.content) | translate }} ({{ note.score }})
                  </p>
              </ng-template>
          </div>
        </div>
    </div>
    
    <p #reviewContent class="text-sm sm:text-sm text-palette-gray-700 leading-6 font-normal {{ isExpanded ? 'line-clamp-none':'line-clamp-3'}}">
        <!-- {{ item.content }} -->
        <ng-container
        [ngTemplateOutlet]="commentContent"
        [ngTemplateOutletContext]="{
          isViewMore: !isShowAllContent && contentHeight > maxTruncatedTextHeight,
          isTranslate: isTranslate
        }">
      </ng-container>
    </p>
    
    <button *ngIf="isLongText" (click)="toggleContent()" class="text-palette-blue-700 text-sm font-semibold">{{ isExpanded ? ('COMMON.SHOW_LESS' | translate ) : ('COMMON.VIEW_MORE' | translate) }}</button> 
    
    <div *ngIf="item.medias && item.medias.length > 0 && item.medias.length <= 5" class="list-images flex flex-row flex-wrap">
        <div *ngFor="let image of item.medias;let i = index" class="!mr-2 !mt-2 cursor-pointer" (click)="onClickImage(item.medias, i)">
          <ng-container *ngIf="isFileVideo(image.type); else showImage">
            <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="assets/images/send-review/video_default_thumb.jpg" (error)="replaceWithDefaultImage($event)" alt="">
          </ng-container>
          <ng-template #showImage>
            <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="{{baseUrl}}/storage/files/web/{{ image.id }}" (error)="replaceWithDefaultImage($event)" alt="">
          </ng-template>
        </div>          
    </div>

    <div *ngIf="item.medias && item.medias.length > 5" class="list-images flex flex-row flex-wrap">
        <div *ngFor="let image of item.medias; let i = index" (click)="onClickImage(item.medias, i)"
         class="relative cursor-pointer !mr-2 !mt-2">
            <ng-container *ngIf="i < 5">
                <!-- <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="{{baseUrl}}/storage/files/web/{{ image.id }}" (error)="replaceWithDefaultImage($event)" alt=""> -->
                <ng-container *ngIf="isFileVideo(image.type); else showImage">
                  <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="assets/images/send-review/video_default_thumb.jpg" (error)="replaceWithDefaultImage($event)" alt="">
                </ng-container>
                <ng-template #showImage>
                  <img class="!w-20 h-20 max-w-[80px] max-h-20 rounded-lg" src="{{baseUrl}}/storage/files/web/{{ image.id }}" (error)="replaceWithDefaultImage($event)" alt="">
                </ng-template>
                <div *ngIf="i==4" class="bg-[#10182866] bg-opacity-40 !w-20 h-20 max-w-[80px] max-h-20 rounded-lg absolute top-0 left-0 z-1 text-white text-sm flex flex-row items-center justify-center">View more</div>
            </ng-container>            
        </div>  
    </div>
 
    <!-- <div class="action flex flex-row space-x-[10px] text-sm text-palette-gray-400 font-semibold !mt-2">
        <div (click)="handleClickHelpful()" class="cursor-pointer flex flex-row items-center space-x-2 !py-2">
            <ng-template *ngIf="isHelpful; then helpful; else helpless">
            </ng-template>                       
            <ng-template #helpful>
                <img class="!w-5 h-5" src="assets/images/temporary/thumbs-up-blue.svg" alt="">
            </ng-template>
            <ng-template #helpless>
                <img class="!w-5 h-5" src="assets/images/temporary/thumbs-up.svg" alt="">
            </ng-template>
           
            <p [ngClass]="{'text-palette-blue-700': isHelpful}">{{'DETAIL.HELPFUL' | translate}}</p>
        </div>

        <div (click)="handleClickReport()" class="cursor-pointer flex flex-row items-center space-x-2 !py-2 !px-[14px]">
            
            <ng-template *ngIf="isReported; then report; else unreport">
            </ng-template>                       
            <ng-template #report>
                <img class="!w-5 h-5" src="assets/images/temporary/flag-blue.svg" alt="">
            </ng-template>
            <ng-template #unreport>
                <img class="!w-5 h-5" src="assets/images/temporary/flag.svg" alt="">
            </ng-template>
           
            <p [ngClass]="{'text-palette-blue-700': isReported}">{{'DETAIL.REPORT' | translate}}</p>
        </div>
    </div> -->
</div>

<app-toast></app-toast>

<ng-template #commentContent let-isViewMore="isViewMore">
    <ng-template [ngIf]="isTranslate" [ngIfElse]="notTranslate">
      <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
        <div
          class="whitespace-pre-wrap comment-content text-sm font-normal emoji {{
            isViewMore ? 'truncated-text' : 'block'
          }}"
          [ngClass]="isLiveView ? 'text-palette-base-white opacity-80' : 'text-palette-gray-900'"
          [innerHTML]="translateContent() + (isViewMore ? ' ...' : '')"></div>
      </ng-template>
  
      <ng-template #loadingTranslate>
        <div class="flex flex-col justify-center">
          <p-skeleton width="100px" height="12px"></p-skeleton>
          <p-skeleton class="mb-1" width="100%" height="12px"></p-skeleton>
          <p-skeleton width="50%" height="12px"></p-skeleton>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #notTranslate>
      <div
        class="whitespace-pre-wrap comment-content text-sm font-normal emoji {{
          isViewMore ? 'truncated-text' : 'block'
        }}"
        [ngClass]="isLiveView ? 'text-palette-base-white opacity-80' : 'text-palette-gray-900'"
        [innerHTML]="formattedContent() + (isViewMore ? ' ...' : '')"></div>
    </ng-template>
  
    <div>
      <div class="float-left">
        <ng-template [ngIf]="isTranslate" [ngIfElse]="viewTrans">
          <ng-container *ngIf="formattedContent()">
            <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
              <div class="translate-action" (click)="isTranslate = false">
                {{ 'COMMON.SEE_ORIGINAL' | translate }}
              </div>
            </ng-template>
            <ng-template #loadingTranslate>
              <div class="font-semibold w-fit text-palette-gray-700 text-sm">
                {{ 'COMMON.TRANSLATING' | translate }}...
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
        <ng-template #viewTrans>
          <ng-container *ngIf="isShowTranslateButton(item)">
            <div class="translate-action" (click)="onTranslateText()">
              {{ 'COMMON.VIEW_TRANSLATION' | translate }}
            </div>
          </ng-container>
        </ng-template>
      </div>
     
    </div>
  </ng-template>